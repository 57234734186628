<template>
	<div class="box box_mm">
		<div class="box_main">
			<div class="search_box">
				<div class="search_item">
					<span style="width: 0.8rem;">标题：</span>
					<el-input v-model="searchinput" placeholder="请输入" style="width: 2rem;"></el-input>
				</div>
				<div class="search_item">
					<el-button type="primary" @click="search">搜索</el-button>
					<el-button type="success" @click="addnew">新建民意</el-button>
				</div>
			</div>
			<div class="box_cont">
				<div class="btn_list">
				</div>
				<div style="height: 76vh;box-sizing: border-box;">
					<MyTable ref="zjlist" :tableData="tableData" :tableHeader="tableHeader" :showbm="true"
						:czwidth="180" @getdata="gettable" @changswitch="changswitch">
						<template slot="btns" slot-scope="{ scope }">
							<el-button @click="showdetail(scope.row)" type="primary" size="mini">详情</el-button>
							<el-button v-show="scope.row.state==3" @click="showyy(scope.row)" type="warning"
								size="mini">驳回原因</el-button>
						</template>
					</MyTable>
				</div>
			</div>
		</div>

		<detail v-if="showtcdetail" :id="nowid" @closedetail="showtcdetail=false"></detail>
	</div>
</template>

<script>
	import detail from './detail_tc'
	export default {
		components: {
			detail
		},
		data() {
			return {
				searchinput: '',
				tableHeader: [{
						prop: 'id',
						name: '序',
						width: '50'
					},
					{
						prop: 'name',
						name: '标题',
						width: ''
					},
					{
						prop: 'sqmytype_name',
						name: '所属类别',
						width: '150'
					},
					{
						prop: 'sqmychannel_name',
						name: '来源渠道',
						width: '150'
					},
					{
						prop: 'user_name',
						name: '发起人',
						width: '120'
					},
					{
						prop: 'state',
						name: '状态',
						width: '120',
						type: 'sqmy_type'
					},
					{
						prop: 'createtime',
						name: '创建时间',
						width: '150'
					}
				],
				tableData: [],
				showtcdetail: false,
				nowid: ''
			}
		},
		mounted() {
			this.gettable()
		},
		methods: {
			showyy(row) {
				this.$post({
					url: '/api/sqmy/details',
					params: {
						id: row.id
					}
				}).then((res) => {
					this.$alert(res.sh_opinion, '驳回原因', {
						confirmButtonText: '确定',
						callback: action => {

						}
					})
				})

			},
			showdetail(row) {
				this.nowid = row.id
				this.showtcdetail = true
			},
			postdata(row) {

			},
			showeidt(row) {
				this.$router.push('/system/get_file/edit?id=' + row.id)
			},
			changswitch(row) {
				this.$post({
					url: '/api/room/edit',
					params: row
				}).then((res) => {
					this.$message({
						type: 'success',
						message: '操作成功!'
					})
					this.gettable()
				})
			},
			search() {
				this.$refs.zjlist.inputpage = 1
				this.gettable()
			},
			gettable() {
				this.$post({
					url: '/api/sqmy/index',
					params: {
						name: this.searchinput,
						p: this.$refs.zjlist.inputpage,
						size: this.$refs.zjlist.pagesize
					}
				}).then((res) => {
					this.tableData = res.list
					this.$refs.zjlist.total = res.count
				})
			},
			addnew() {
				this.$router.push('/system/people_opinions_list_add')
			},
			showdate(row) {
				this.$refs.myroomdate.dialogVisible = true
			},
		}
	}
</script>

<style lang="scss" scoped>
	@import 'list.scss';
</style>